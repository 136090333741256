import React, { useState, useEffect } from 'react';
import HeroDesktop from './HeroDesktop'
import HeroMobile from './HeroMobile'

const Header = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {

    function setState() {
      if (window.innerWidth > window.innerHeight) {
        setIsDesktop(true);
        setIsMobile(false);
      } else {
        setIsMobile(true);
        setIsDesktop(false);
      }
    }

    window.addEventListener('resize', setState);
    setState(); 
  }, []);

  if (isDesktop) {
    return (<HeroDesktop />)
  }
  else {
    return (<HeroMobile />)
  }
};

export default Header;
