import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';

const HeroImg = ({filename, alt, zIndex, wrapperStyleOverride}) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1366) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => n.node.relativePath.includes(filename));

      if (!image) return null;
      let wrapperStyle = {zIndex:zIndex}
      let imageStyle = { objectFit: "contain"}
      wrapperStyle = overrideJsonObject(wrapperStyle, wrapperStyleOverride)

      const imageFluid = image.node.childImageSharp.fluid;
      return (
          <Img style={wrapperStyle} imgStyle={imageStyle} alt={alt} fluid={imageFluid}/>);
    }}

  />
);

const overrideJsonObject = (jsonObject, override) => {
  for (var key in override) {
    console.log(key)
    console.log(override[key])
    jsonObject[key] = override[key]
  }
  return jsonObject
}

HeroImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default HeroImg;
