import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import resume from '../../images/resume_sanitized.pdf'
import Parallax from 'parallax-js'
import HeroImg from '../Image/HeroImage'
import FloatingBlock from '../Elements/FloatingBlock'

const HeaderDesktop = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, cta } = hero;

  useEffect(() => {
    var scene = document.getElementById('float');
    new Parallax(scene, {
      relativeInput: true
    });
  }, []);

  let wrapperStyleOverride = {
    height: "100%"
  }
  let leatImage   = <HeroImg alt={title} filename="hero_leaf.png" zIndex="0" wrapperStyleOverride={wrapperStyleOverride} />
  let stemImage   = <HeroImg alt={title} filename="hero_stem.png" zIndex="1" wrapperStyleOverride={wrapperStyleOverride}/>
  let squareImage = <HeroImg alt={title} filename="hero_square.png" zIndex="2" wrapperStyleOverride={wrapperStyleOverride}/>
  let flowerImage = <HeroImg alt={title} filename="hero_flower.png" zIndex="3" wrapperStyleOverride={wrapperStyleOverride}/>
  let textImageIntro   = <HeroImg alt={title} filename="hero_textintro.png" zIndex="4" wrapperStyleOverride={wrapperStyleOverride}/>
  let textImageOccupation   = <HeroImg alt={title} filename="hero_textOccupation.png" zIndex="4" wrapperStyleOverride={wrapperStyleOverride}/>
  let button = (<p className="hero-cta">
                  <span className="cta-btn cta-btn--hero">
                    <a href={resume} target="_blank">
                      {cta || ' Resume'}
                    </a>
                  </span>
                </p>)

  return (
    <section id="hero" className="jumbotron" data-relative-input="true" style={{backgroundColor:"#fca586"}}>
      {button}
      <Container className="h-100 w-100" style={{position: "relative"}} id="float">
          <FloatingBlock content={leatImage} depthData="0.0" zIndex="0" />
          <FloatingBlock content={stemImage} depthData="0.05" zIndex="1"/>
          <FloatingBlock content={squareImage} depthData="0.07" zIndex="1"/>
          <FloatingBlock content={flowerImage} depthData="0.1" zIndex="1"/>
          <FloatingBlock content={textImageIntro} depthData="0.12" zIndex="1"/>
          <FloatingBlock content={textImageOccupation} depthData="0.12" zIndex="1"/>
      </Container>
    </section>
  );
};

export default HeaderDesktop;
