import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import resume from '../../images/resume_sanitized.pdf';
import HeroImg from '../Image/HeroImage';
import FloatingBlock from '../Elements/FloatingBlock';

const HeaderMobile = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const leafWrapperStyleOverride = {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
  };

  const flowerWrapperStyleOverride = {
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
    right: '-30%',
    top: '-20%',
  };

  const leafImage = (
    <HeroImg
      alt={title}
      filename="hero_leaf.png"
      zIndex="0"
      wrapperStyleOverride={leafWrapperStyleOverride}
    />
  );
  const stemImage = (
    <HeroImg
      alt={title}
      filename="hero_stem.png"
      zIndex="1"
      wrapperStyleOverride={flowerWrapperStyleOverride}
    />
  );
  const flowerImage = (
    <HeroImg
      alt={title}
      filename="hero_flower.png"
      zIndex="3"
      wrapperStyleOverride={flowerWrapperStyleOverride}
    />
  );

  return (
    <section
      id="hero"
      className="jumbotron"
      style={{ backgroundColor: '#fca586', position: 'relative', zIndex: 100, overflow: 'hidden' }}
    >
      <Container style={{ zIndex: 2 }}>
        <FloatingBlock content={leafImage} depthData="0.0" bottom="0px" zIndex="-1" />
        <FloatingBlock content={stemImage} depthData="0.05" zIndex="-1" />
        <FloatingBlock content={flowerImage} depthData="0.1" zIndex="-1" />

        <Fade bottom duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || 'Hi, my name is'}{' '}
            <span className="text-color-main">{name || 'Shunxu Huang'}</span>
            <br />
            {subtitle || 'I write code for a living.'}
          </h1>
        </Fade>
        <Fade bottom duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <a href={resume} target="_blank" rel="noreferrer">
                {cta || 'Resume'}
              </a>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default HeaderMobile;
