import React from 'react';
import Fade from 'react-reveal/Fade';

const FloatingBlock = ({ depthData, content, left, right, width, top, bottom, zIndex }) => {
    width = width || "100%"
    let style = {position: "absolute", height: "100%", width: "100%", zIndex:zIndex}
    if (left) style.left = left
    if (right) style.right = right
    if (top) style.top = top
    if (bottom) style.bottom = bottom
    return (
        <div data-depth={depthData} style={style}>
            {/* <Fade duration={1500} delay={500} style={{position: "relative", height: "100%"}}> */}
                {content}
            {/* </Fade> */}
        </div>);
}


export default FloatingBlock;
